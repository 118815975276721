<template>
  <div class="quickScore">
    <div class="tournBlock">
      <b-row>
        <b-col cols="5">
          <span class="RoundGroup">
            <template v-if="title.includes('Score')">
              <span class="roudnTextNum" :style="'display:' + showDisplay">
                <template v-if="data.round != null">
                  R{{data.round}}
                </template>
                <template v-else>
                  R{{data.rounds_played}}
                </template>
              </span>
            </template>
            <img class="sponsorLogo" :src='data.event_sponsor_logo'>
          </span>
        </b-col>
        <b-col cols="7" class="noPadLeft">
          <h3 class="Name-B">
            <template v-if="tourCode == 'wpg'">
              <b-link class="shortNameLink" :href="'https://aus.cw.au.dotgolf.com.au/wpga-tour-aus'" target="_parent">
                {{ data.short_name }}
              </b-link>
            </template>
            <template v-else-if="tourCode == 'aus'">
              <b-link class="shortNameLink" :href="'https://aus.cw.au.dotgolf.com.au/pga-tour-aus'" target="_parent">
                {{ data.short_name }}
              </b-link>
            </template>
            <template v-else-if="tourCode == 'all'">
              <b-link class="shortNameLink" :href="'https://aus.cw.au.dotgolf.com.au/pga-pro-am-series'" target="_parent">
                {{ data.short_name }}
              </b-link>
            </template>
            <template v-else-if="tourCode == 'snr'">
              <b-link class="shortNameLink" :href="'https://aus.cw.au.dotgolf.com.au/pga-legends-tour'" target="_parent">
                {{ data.short_name }}
              </b-link>
            </template>
          </h3>
          <h3 class="course-information-date">
            {{ tournDates }}
          </h3>
        </b-col>
      </b-row>
    </div>
    <div>
      <ReportTable
        :data="data"
        :title="title"
        :home="home"
        :updown="updown"
        :config="config"
        :team="teamEvent"
        :match="data.match_play"
        :show="show"
      />
      <template>


        <template>
          <b-row class="reportBtns" :class="{reportMobBtns : MobWidget == 'Y'}">
            <b-col cols="12" class="belowNorm">
              <b-row>
                <b-col class="padRight-none">
                  <b-nav-item
                    class="LinkReport"
                    :href="'https://aus.cw.au.dotgolf.com.au/' + text"
                    target="_parent"
                  >
                    <p class="quickLink reportMob">
                      <template v-if="currentReport.includes('entries')">
                        <template v-if="!listOfreportNames">
                          {{listOfreportNames}}
                        </template>
                        <template>
                          See More
                        </template>
                      </template>
                      <template v-else-if="currentReport.includes('score')">
                        Full leaderboard
                      </template>
                      <template v-else>
                        See More
                      </template>
                      <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                    </p>
                  </b-nav-item>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "ReportsHomeTab",
  components: {
    ReportTable,
  },
  props: ["data", "Year", "code", "currentReport", "reportURL", "config" , "course_code", "title","tournDates", "updateSize", "widgetHeight", "reports", "tourCode", "show", 'runData'],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      tab: "tab",
      cell: "cell",
      home: true,
      MobWidget: process.env.VUE_APP_MOB_WIDGET,
      update: '',
      updateTwo: '',
      updateThree: '',
      listOfreportNames: '',
      showDisplay: '',
      text: ''
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.data.live_scoring === "Y") message = " - Livescoring is on";
      else message = " - " + this.data.round_head;
      return message;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    // title: function(title) {
    //   if (this.currentReport.includes("entry")) title = "Tournament Entries";
    //   else if (this.currentReport.includes("draw")) title = "Draw";
    //   else if (this.currentReport.includes("latest")) title = "Live";
    //   else if (this.currentReport.includes("score")) title = "Scores";
    //   else if (this.currentReport.includes("result")) title = "Final Result";

    //   return title;
    // },
  },
  methods: {
    myFunction: function() {
      let h = window.outerWidth;
      let txt = h;
      this.text = txt;
      if(this.text > 990 && this.text < 1199) {
        this.showDisplay = 'none!important'
      } else if(this.text < 989) {
        this.showDisplay = 'none!important'
      }  else {
        this.showDisplay = 'inline-block!important'
      }
    },
    updateStuff: function() {
      console.log('run update')
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    upDown() {
      this.updown = !this.updown;
    },
    reportListText: function() {
      this.reports.forEach((value) => {
        if (value.report_title.includes('Draw')) {
          this.listOfreportNames = 'Draw'
          console.log("this.listOfreportNames")
          console.log(this.listOfreportNames)
        }
      });
    }
  },
  created() {
    this.reportListText()
    this.myFunction();
    window.addEventListener('resize', this.myFunction);
  },
  //  created() {
  //    console.log('UPDATEE')
  //      if (this.widgetHeight < '400' && this.widgetHeight > '311') {
  //           clearTimeout(this.update); 
  //           console.log('In Clear One')
  //           this.update = setInterval(() => {
  //               this.updateStuff();
  //               this.updateSize();
  //             }, 2000
  //           );
  //      } else if(this.widgetHeight < '310') {
  //           clearTimeout(this.updateTwo);
  //           console.log('In Clear Two')
  //           this.updateTwo = setInterval(() => {
  //               this.updateStuff();
  //               this.updateSize();
  //             }, 2000
  //           );
  //      } else if(this.widgetHeight > '401') {
  //           clearTimeout(this.updateThree);
  //           console.log('In Clear Three')
  //           this.updateThree = setInterval(() => {
  //               this.updateStuff();
  //               this.updateSize();
  //             }, 2000
  //           );
  //      } 
  // },
  mounted() {
    apiCall
      .report(this.currentReport)
      .then(({ data }) => {
          this.data = data;
        if (this.tourCode == 'wpg') {
          this.text = 'wpga-tour-aus'
        } else if(this.tourCode == 'all') {
           this.text = 'pga-pro-am-series'
        } else if(this.tourCode == 'snr') {
           this.text = 'pga-legends-tour'
        } else {
          this.text = 'leaderboard'
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep td.up.posDown {
  text-align: center;
}
::v-deep span.multiCourseSpan {
    float: right;
    margin-top: -17px;
    display: none;
}
::v-deep span.spanPos {
    position: relative;
    left: -5px;
}
span.roudnTextNum {
  border: 1px solid;
  display: inline-grid;
  font-size: 16pt;
  text-align: center;
  position: relative;
  top: 12px;
  width: 29px;
  height: 33px;
}
::v-deep a.shortNameLink {
  color: #000000!important;
}
a.shortNameLink:hover {
  text-decoration: none;
  color: #34caa9!important;
}
.reportBtns {
  /* position: absolute;
  bottom: -28px;
  width: 100%; */
}
.reportMobBtns {
  bottom: inherit!important;
}
::v-deep li.nav-item.LinkReport > a.nav-link {
  padding-left: 15px;
  padding: 0;
}
::v-deep li.nav-item.LinkReport {
  text-align: center;
}
img.rightLittleArrow {
  margin-left: 5px;
  margin-top: -1px;
}
.quickLink {
  color: #003C52;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 12px;
  /* top: 6em; */
  top: 1.5em;
  text-align: right;
  /* padding-bottom: 35px; */
  /* margin-right: 15px; */
  margin-right: 20px;
}
.noPadLeft {
  padding-left: 0;
}
.padRight-none {
  padding-right: 15px;
  border-right: 1px solid white;
}
.padLeft-none {
  padding-left: 0;
  border-left: 1px solid white;
  padding-right: 30px;
}
a.btn.widgetButtons.btn-secondary {
  background: #33cba9;
  border: #33cba9;
  color: #fff!important;
  width: 100%;
  border-radius: 0;
}
a.btn.widgetButtons.btn-secondary:hover {
  background: #003C52;
  border: #003C52;
  color: #fff!important;
  width: 100%;
  border-radius: 0;
}
a.btn.widgetButtons-blue.btn-secondary:hover {
  background: #33cba9;
  border: #33cba9;
  color: #fff!important;
  width: 100%;
  border-radius: 0;
  margin-top: 36px;
  /* margin-top: 10px; */
}
a.btn.widgetButtons-blue.btn-secondary {
  background: #003C52;
  border: #003C52;
  color: #fff!important;
  width: 100%;
  border-radius: 0;
  margin-top: 36px;
  /* margin-top: 10px; */
}
.sponsorLogo {
  max-height: 60px;
  max-width: 130px;
  /* float: left; */
  margin-top: 10px;
  padding-left: 10px;
}
.tournBlock {
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}
h3.course-information {
  line-height: 0px;
  padding-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  color: #000000;
}
h3.course-information-date {
  line-height: 0px;
  padding-bottom: 14px;
  font-size: 14px;
  font-weight: 400!important;
  color: #003c52!important;
  font-weight: 50
}
h3.Name-B {
  font-size: 16px;
  padding-top: 15px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 12px;
}
.logoP {
  max-width: 100px;
  padding-top: 9px;
  position: absolute;
  z-index: 9999;
  top: 8%;
  right: 45px;
}
.overlay-part {
  /* background: #fff; */
  position: absolute;
  opacity: .6;
  width: 50%;
  height: 100px;
  top: 38px;
  right: 14px;
}
.backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 280px; */
  /* height: 180px; */
  /* height: 140px; */
  height: 55px;
}
.quickScore {
  padding: 0 0 0px 0;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.25rem;
  border-top: 0px;
}
li {
  list-style: none;
}
.links {
  text-align: center;
  padding: 10px;
}
.red {
  background-color: #bf4258;
}
.blue {
  background-color: #1a2337;
}
.links > li > a {
  color: #fff !important;
}
@media only screen and (max-width: 1200px) {
  .sponsorLogo {
    max-height: 50px;
    max-width: 130px;
    /* float: left; */
    margin-top: 20px;
    padding-bottom: 7px;
  }
}
@media only screen and (max-width: 1400px) {
  .tournBlock {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .belowNorm {
    flex: 0 0 100%!important;
    max-width: 100%!important;
  }
}
@media only screen and (max-width: 400px) {
  .reportMob {
    top: 1em!important;
  }
}
@media only screen and (max-width: 419px) {
  .backgroundImage {
    display: none;
  }
  .tournBlock {
    background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    /* padding-top: 12px;
    padding-bottom: 12px; */
  }
}
</style>